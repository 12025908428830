<template>
    <div class="container col mt-5">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="security-logs"
          role="tabpanel"
          aria-labelledby="security-logs-tab"
        >
          <div class="container mt-3">
            <h3>Request Logs</h3>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Request Details</th>
                  <th scope="col">API Credentials</th>
                  <th scope="col">Status Code</th>
                  <th scope="col">Response</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in paginatedLogs" :key="log.id">
                  <td>
                    <strong>{{ log.method }}</strong> {{ log.path }}
                    <br />
                    <small>Variables: {{ log.variables }}</small>
                  </td>
                  <td>{{ log.credentials }}</td>
                  <td>
                    <span :class="getBadgeClass(log.statusCode)">
                      {{ log.statusCode }}
                    </span>
                  </td>
                  <td>
                    <router-link :to="{ name: 'RequestDetails', params: { id: log.id } }">
                      <span class="response-preview">
                        {{ log.response.substring(0, 10) }}
                        <span v-if="log.response.length > 10">...</span>
                      </span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <div>
                Showing {{ (currentPage - 1) * recordsPerPage + 1 }} to
                {{ Math.min(currentPage * recordsPerPage, totalRecords) }} of {{ totalRecords }} entries
              </div>
              <nav>
                <ul class="pagination">
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="prevPage">Previous</button>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="nextPage">Next</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SecurityRequestLogs",
    data() {
      return {
        requestLogs: [
          {
            id: 1,
            method: "GET",
            path: "/api/user/123",
            variables: "user-id=123",
            credentials: "API Key 123",
            statusCode: 200,
            response: '{"username":"123123"}',
          },
          {
            id: 2,
            method: "POST",
            path: "/api/user",
            variables: "name=JohnDoe",
            credentials: "API Key 456",
            statusCode: 201,
            response: "Response details here.sadfasfdasdfsadf..",
          },
          {
            id: 3,
            method: "GET",
            path: "/api/user/123",
            variables: "user-id=123",
            credentials: "API Key 123",
            statusCode: 200,
            response: '{"username":"123123"}',
          },
          {
            id: 4,
            method: "POST",
            path: "/api/user",
            variables: "name=JohnDoe",
            credentials: "API Key 456",
            statusCode: 201,
            response: "Response details here.sadfasfdasdfsadf..",
          },
          {
            id: 5,
            method: "GET",
            path: "/api/user/123",
            variables: "user-id=123",
            credentials: "API Key 123",
            statusCode: 200,
            response: '{"username":"123123"}',
          },
          {
            id: 6,
            method: "POST",
            path: "/api/user",
            variables: "name=JohnDoe",
            credentials: "API Key 456",
            statusCode: 201,
            response: "Response details here.sadfasfdasdfsadf..",
          },
          {
            id: 7,
            method: "GET",
            path: "/api/user/123",
            variables: "user-id=123",
            credentials: "API Key 123",
            statusCode: 200,
            response: '{"username":"123123"}',
          },
          {
            id: 8,
            method: "POST",
            path: "/api/user",
            variables: "name=JohnDoe",
            credentials: "API Key 456",
            statusCode: 201,
            response: "Response details here.sadfasfdasdfsadf..",
          },
          // Add more logs as needed
        ],
        currentPage: 1,
        recordsPerPage: 5,
      };
    },
    computed: {
      totalRecords() {
        return this.requestLogs.length;
      },
      totalPages() {
        return Math.ceil(this.totalRecords / this.recordsPerPage);
      },
      paginatedLogs() {
        const start = (this.currentPage - 1) * this.recordsPerPage;
        const end = start + this.recordsPerPage;
        return this.requestLogs.slice(start, end);
      },
    },
    methods: {
      getBadgeClass(statusCode) {
        if (statusCode >= 200 && statusCode < 300) {
          return 'badge bg-success';
        } else if (statusCode >= 400 && statusCode < 500) {
          return 'badge bg-warning';
        } else if (statusCode >= 500) {
          return 'badge bg-danger';
        } else {
          return 'badge bg-secondary';
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>