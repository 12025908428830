import { createRouter, createWebHistory } from 'vue-router';
import RequestLogs from '../components/RequestLogs.vue';
import RequestDetails from '../components/RequestDetails.vue';
import CredentialsVault from '../components/CredentialsVault.vue';
import EndpointDirectory from '../components/EndpointDirectory.vue';
import ScanConfiguration from '../components/ScanConfiguration.vue';
import VariableAssignment from '../components/VariableAssignment.vue';
import EndpointDetails from '../components/EndpointDetails.vue';
import SequenceBuilder from '../components/SequenceBuilder.vue';

import clerk from "../clerk";

const routes = [
  {
    path: '/request-logs',
    name: 'RequestLogs',
    component: RequestLogs,
  },
  {
    path: '/details/:id',
    name: 'RequestDetails',
    component: RequestDetails,
  },
  {
    path: '/',
    name: 'CredentialsVault',
    component: CredentialsVault,
  },
  {
    path: '/endpoint-directory',
    name: 'EndpointDirectory',
    component: EndpointDirectory,
  },
  {
    path: '/endpoint-directory/:id/details',
    name: 'EndpointDetails',
    component: EndpointDetails,
  },
  {
    path: '/scan-configuration',
    name: 'ScanConfiguration',
    component: ScanConfiguration,
  },
  {
    path: '/variable-assignment',
    name: 'VariableAssignment',
    component: VariableAssignment,
  },
  {
    path: '/sequence-builder',
    name: 'SequenceBuilder',
    component: SequenceBuilder,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard to protect all routes
router.beforeEach((to, from, next) => {
    if (!clerk.user) {
      // If the user is not authenticated, redirect to the sign-in page
      clerk.openSignIn();
    } else {
      // If the user is authenticated, proceed to the route
      next();
    }

});

export default router;