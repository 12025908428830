<template>
  <div class="card w-100" style="max-width: 800px;">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h1 class="h4 mb-0">API Request Details</h1>
      <button @click="goBack" class="btn btn-outline-secondary">
        <i class="bi bi-arrow-left"></i> Back
      </button>
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs mb-4">
        <li class="nav-item" v-for="tab in tabs" :key="tab">
          <a class="nav-link" :class="{ active: activeTab === tab }" @click.prevent="activeTab = tab" href="#">
            {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
          </a>
        </li>
      </ul>

      <div class="tab-content">

        <div v-if="activeTab === 'parameters'" class="tab-pane fade show active">
          <h2 class="h5 mb-3">Query Parameters</h2>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Enum Values</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="param in queryParameters" :key="param.id">
                  <td>{{ param.name }}</td>
                  <td>{{ param.schema_type }}</td>
                  <td>{{ param.required ? 'Yes' : 'No' }}</td>
                  <td>{{ param.enum_values || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="activeTab === 'requests'" class="tab-pane fade show active">
          <h2 class="h5 mb-3">API Requests</h2>
          <div v-for="request in apiRequests" :key="request.id" class="mb-4">
            <h3 class="h6">
              <span class="badge" :class="methodBadgeClass(request.method)">{{ request.method }}</span>
            </h3>
            <div class="bg-light p-3 rounded">
              <pre><code>{{ generateCurlCommand(request) }}</code></pre>
              <button class="btn btn-sm btn-outline-secondary mt-2" @click="copyCurlCommand(request)">Copy Command</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/apiUtils'; 
export default {
  name: 'ApiEndpointDetails',
  data() {
    return {
      endpoint: {},
      apiRequests: [],
      queryParameters: [],
      activeTab: 'requests',
      tabs: ['requests', 'parameters'],
      endpointId: null
    };
  },
  methods: {
    async fetchEndpointDetails() {
      // Implement API call here
      try {
            const data = await get(this.$clerk, `/endpoints/${this.endpointId}`);
            this.apiRequests = data.api_requests;
            this.endpoint = data.endpoint;
            this.queryParameters = data.query_parameters;
        } catch (error) {
            console.error('Error fetching endpoint details:', error);
        }
    },
    generateQueryString() {
      const params = new URLSearchParams();
      this.queryParameters.forEach(param => {
        if (param.enum_values) {
          params.append(param.name, param.enum_values.split(',')[0]);
        } else if (param.schema_type === 'boolean') {
          params.append(param.name, 'false');
        } else if (param.schema_type === 'integer') {
          params.append(param.name, '10');
        } else {
          params.append(param.name, `example_${param.name}`);
        }
      });
      return params.toString();
    },
    methodBadgeClass(method) {
      const classes = {
        GET: 'bg-success',
        POST: 'bg-primary',
        PUT: 'bg-warning',
        DELETE: 'bg-danger',
        PATCH: 'bg-info'
      };
      return classes[method] || 'bg-secondary';
    },
    goBack() {
      this.$router.go(-1);
    },
    generateCurlCommand(request) {
      let command = `curl -X ${request.method} "${this.endpoint.hostname}${this.endpoint.path}`;
      
      if (request.method === 'GET') {
        const queryString = this.generateQueryString();
        if (queryString) {
          command += `?${queryString}"`;
        } else {
          command += '"';
        }
      } else {
        command += '"';
      }

      if (request.example_json) {
        command += ` -H "Content-Type: application/json" -d '${request.example_json}'`;
      }

      return command;
    },
    copyCurlCommand(request) {
      const command = this.generateCurlCommand(request);
      navigator.clipboard.writeText(command)
        .then(() => alert('Curl command copied to clipboard!'))
        .catch(err => console.error('Failed to copy: ', err));
    }
  },
  created() {
    this.endpointId = this.$route.params.id;
    this.fetchEndpointDetails();
  }
};
</script>

<style scoped>
/* Card styles */
.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

/* Tab styles */
.nav-tabs .nav-link {
  color: #495057;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

/* Code block styles */
pre {
  background-color: #f1f1f1;
  padding: 1em;
  border-radius: 5px;
  white-space: pre-wrap;
  word-break: break-word;
}
.card-header .btn-outline-secondary {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
</style>