<template>
  <div class="sequence-info">
    <div class="row">
      <div class="col-md-12">
        <h3 class="mb-4">Current Sequences</h3>
        <div class="sequence-list">
          <div v-for="sequence in sequences" :key="sequence.id" class="sequence-item">
            <div class="sequence-header">
              <h4>{{ sequence.name }}</h4>
              <div class="sequence-actions">
                <button class="btn btn-sm btn-outline-primary" @click="editSequence(sequence)">Edit</button>
                <button class="btn btn-sm btn-outline-danger" @click="deleteSequence(sequence.id)">Delete</button>
              </div>
            </div>
            <p class="sequence-description">{{ sequence.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'SequenceInfo',
  setup() {
    const sequenceName = ref('');
    const description = ref('');
    const sequences = ref([]);

    const fetchSequences = async () => {
      // Simulated API call
      sequences.value = [
        { id: 1, name: 'Sequence 1', description: 'This is the first sequence' },
        { id: 2, name: 'Sequence 2', description: 'This is the second sequence' },
        { id: 3, name: 'Sequence 3', description: 'This is the third sequence' },
      ];
    };

    const createSequence = () => {
      // Implement creation logic here
      console.log('Creating sequence:', sequenceName.value, description.value);
    };

    const editSequence = (sequence) => {
      // Implement edit logic here
      console.log('Editing sequence:', sequence);
    };

    const deleteSequence = (id) => {
      // Implement delete logic here
      console.log('Deleting sequence:', id);
    };

    onMounted(fetchSequences);

    return {
      sequenceName,
      description,
      sequences,
      createSequence,
      editSequence,
      deleteSequence,
    };
  },
};
</script>

<style scoped>
.sequence-info {
  padding: 20px;
}

.sequence-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
}

.sequence-item {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  transition: box-shadow 0.3s ease;
}

.sequence-item:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.sequence-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sequence-header h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #007bff;
}

.sequence-actions {
  display: flex;
  gap: 10px;
}

.sequence-description {
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
</style>