<template>
  <div class="card w-100" style="max-width: 1200px;">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h1 class="h4 mb-0">Create Vulnerability Check Sequence</h1>
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs mb-4">
        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
          <a class="nav-link" :class="{ active: activeTab === index }" @click.prevent="activeTab = index" href="#">
            {{ tab.title }}
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div v-for="(tab, index) in tabs" :key="index" class="tab-pane fade" :class="{ 'show active': activeTab === index }">
          <component 
            :is="tab.component" 
            :selectedApi="selectedApi"
            @update-selected-api="updateSelectedApi" 
          />
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import SequenceInfo from './SequenceInfo.vue';
import APISelection from './APISelection.vue';
// import RequestConfig from './RequestConfig.vue';
// import ResponseHandling from './ResponseHandling.vue';
// import VulnerabilityRules from './VulnerabilityRules.vue';

export default {
  name: 'SequenceBuilder',
  components: {
    SequenceInfo,
    APISelection,
    // RequestConfig,
    // ResponseHandling,
    // VulnerabilityRules,
  },
  setup() {
    const activeTab = ref(0);
    const selectedApi = ref([]);
    const tabs = [
      { title: 'API Selection', component: APISelection },
      { title: 'Sequence Info', component: SequenceInfo },
      // { title: 'Request Config', component: RequestConfig },
      // { title: 'Response Handling', component: ResponseHandling },
      // { title: 'Vulnerability Rules', component: VulnerabilityRules },
    ];

    const nextTab = () => {
      if (activeTab.value < tabs.length - 1) {
        activeTab.value++;
      }
    };

    const prevTab = () => {
      if (activeTab.value > 0) {
        activeTab.value--;
      }
    };

    const updateSelectedApi = (api) => {
      selectedApi.value = api;
    };

    return {
      activeTab,
      tabs,
      nextTab,
      prevTab,
      updateSelectedApi,
      selectedApi,
    };
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.nav-tabs .nav-link {
  color: #495057;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

@media (max-width: 768px) {
  .nav-tabs {
    flex-direction: column;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0.5rem;
  }

  .nav-tabs .nav-link {
    border-radius: 0.25rem;
  }
}
</style>