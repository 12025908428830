<template>
    <div class="container mt-4">
      <h3>Variable Assignments</h3>
      <p class="text-muted">
        Our automated system could also populate the possible values once you have started the scanning.
      </p>
      
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Variable Name</th>
            <th scope="col">Possible Values</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variable, varIndex) in variables" :key="varIndex">
            <td>{{ variable.name }}</td>
            <td>
              <ul class="list-unstyled">
                <li v-for="(value, valIndex) in variable.values" :key="valIndex" @mouseover="hoverValue(varIndex, valIndex)" @mouseleave="leaveValue(varIndex, valIndex)">
                  <div v-if="editIndex.varIndex === varIndex && editIndex.valIndex === valIndex">
                    <input v-model="editableValue" class="form-control d-inline-block" style="width: auto;" />
                    <!-- Check SVG -->
                    <svg @click="confirmEdit(varIndex, valIndex)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-success ms-2" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                    </svg>
                    <!-- Cross SVG -->
                    <svg @click="deleteValue(varIndex, valIndex)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle text-danger ms-2" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                  </div>
                  <div v-else>
                    {{ value }}
                    <!-- Pencil SVG -->
                    <svg v-if="hoveredIndex.varIndex === varIndex && hoveredIndex.valIndex === valIndex" class="ms-2" @click="editValue(varIndex, valIndex)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                    </svg>
                  </div>
                </li>
              </ul>
              <button class="btn btn-success btn-sm mt-2" @click="addValue(varIndex)">Add Value</button>
            </td>
          </tr>
        </tbody>
      </table>
      
      <button class="btn btn-success mt-3" @click="addVariable">Add Variable</button>
  
      <div v-if="changeMessage" class="alert alert-success mt-3" role="alert">
        {{ changeMessage }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:"VariableAssignment",
    data() {
      return {
        variables: [
          { name: 'Variable1', values: ['Value1', 'Value2'] },
          { name: 'Variable2', values: ['Value3', 'Value4'] },
          // Add more initial variables if needed
        ],
        editIndex: { varIndex: 1, valIndex: 2 },
        hoveredIndex: { varIndex: 3, valIndex: 4 },
        editableValue: '',
        changeMessage: ''
      };
    },
    methods: {
      addVariable() {
        const newName = prompt("Enter variable name:");
        if (newName) {
          this.variables.push({ name: newName, values: [] });
        }
      },
      addValue(varIndex) {
        const newValue = prompt("Enter possible value:");
        if (newValue) {
          this.variables[varIndex].values.push(newValue.trim());
        }
      },
      editValue(varIndex, valIndex) {
        this.editIndex = { varIndex, valIndex };
        this.editableValue = this.variables[varIndex].values[valIndex];
      },
      confirmEdit(varIndex, valIndex) {
        this.$set(this.variables[varIndex].values, valIndex, this.editableValue.trim());
        this.editIndex = { varIndex: null, valIndex: null };
        this.changeMessage = `Value has been changed to "${this.editableValue.trim()}"`;
        setTimeout(() => this.changeMessage = '', 3000);
      },
      deleteValue(varIndex, valIndex) {
        if (confirm("Are you sure you want to delete this value?")) {
          this.variables[varIndex].values.splice(valIndex, 1);
        }
      },
      hoverValue(varIndex, valIndex) {
        this.hoveredIndex = { varIndex, valIndex };
      },
      leaveValue() {
        this.hoveredIndex = { varIndex: null, valIndex: null };
    }
    }
  };
  </script>

<style scoped>
.container {
  max-width: 800px;
}
.ms-1 {
  margin-left: 0.25rem;
}
.ms-2 {
  margin-left: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
</style>