<template>
  <div class="container mt-5 row">
    <div class="col-6"> <!-- search box -->
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Search APIs" v-model="searchQuery" @input="onSearchInput">
        <div class="border icon-container">
          <svg class="bi bi-arrow-down" v-if="!showSearchBox" @click="openSearchBox" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
          </svg>
          <svg v-if="showSearchBox" @click="closeSearchBox" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
          </svg>
        </div>
      </div>
      <div v-if="showSearchBox">
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="api in apis" :key="api.id">
            <strong>{{ api.path }}</strong>
            <div class="d-flex align-items-center">
              <span :class="['method-label', getMethodClass(api.method)]">{{ api.method }}</span>
              <div class="icon-container ms-2" @click="selectApi(api)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
                </svg>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination">
          <button class="btn btn-outline-secondary" :disabled="currentPage === 1" @click="loadEndpoints(currentPage - 1)">
            Previous
          </button>
          <span>Page {{ currentPage }}</span>
          <button class="btn btn-outline-secondary" :disabled="currentPage * itemsPerPage >= total" @click="loadEndpoints(currentPage + 1)">
            Next
          </button>
        </div>
      </div>
    </div>
    <div class="col-6"><!-- selected box-->
      <table class="table">
        <thead>
          <tr>
            <th>Path</th>
            <th>Method</th>
            <th>Credential</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(api, index) in localSelectedApi" 
            :key="api.uniqueId"
            draggable="true"
            @dragstart="onDragStart(index)"
            @dragover.prevent="onDragOver(index)"
            @dragleave="onDragLeave"
            @drop="onDrop(index)"
            class="draggable"
            :class="{ 'drag-over': dragOverIndex === index }"
          >
            <td>{{ api.path }}</td>
            <td>
              <span :class="['method-label', getMethodClass(api.method)]">{{ api.method }}</span>
            </td>
            <td>
              <select v-model="api.credential" class="form-select form-select-sm credential-select" @change="updateApiCredential()">
                <option v-for="cred in credentials" :key="cred.id" :value="cred.id">
                  {{ cred.name }}
                </option>
              </select>
            </td>
            <td>
              <button class="btn btn-danger btn-sm" @click="removeApi(api.uniqueId)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-primary mt-3" @click="saveConfig">Save</button>
  </div>
</template>
  
<script>
import { get, post } from '@/utils/apiUtils'; // Ensure you have these utility functions implemented
import { v4 as uuidv4 } from 'uuid'; // Import UUID library to generate unique IDs

export default {
  name: 'APISelection',
  data() {
    return {
      showSearchBox: false,
      searchQuery: '',
      apis: [],
      currentPage: 1,
      itemsPerPage: 10,
      total: 0,
      credentials: [], // New: to store available credentials
      draggingIndex: null, // To store the index of the dragged item
      dragOverIndex: null, // To store the index of the element over which the item is being dragged
      localSelectedApi: [] // Local copy of selectedApi
    };
  },
  watch: {
    searchQuery: 'onSearchInput',
    selectedApi: {
      handler(newVal) {
        this.localSelectedApi = [...newVal];
      },
      immediate: true
    }
  },
  props:{
    selectedApi: {
      type: Array,
      default: () => []
    }
  },
  emits:['update-selected-api'],
  methods: {
    getMethodClass(method) {
      const methodClasses = {
        GET: 'method-get',
        POST: 'method-post',
        DELETE: 'method-delete',
        PATCH: 'method-patch',
        PUT: 'method-put'
      };
      return methodClasses[method] || 'method-default';
    },
    closeSearchBox(){
      this.showSearchBox = false;
    },
    openSearchBox(){
      this.showSearchBox = true;
    },
    async loadEndpoints(page = this.currentPage) {
      if (this.searchQuery.length < 3) return;

      try {
        const response = await get(this.$clerk, `/endpoints/search?q=${this.searchQuery}&page=${page}&per_page=${this.itemsPerPage}`);
        this.apis = response.items;
        this.total = response.total;
        this.currentPage = page;
      } catch (error) {
        console.error('Error loading endpoints:', error);
      }
    },
    onSearchInput() {
      this.showSearchBox = true;
      if (this.searchQuery.length >= 3) {
        this.loadEndpoints();
      } else {
        this.apis = [];
      }
    },
    async loadCredentials() {
      try {
        const response = await get(this.$clerk, '/credentials');
        this.credentials = response.items;
      } catch (error) {
        console.error('Error loading credentials:', error);
      }
    },
    updateApiCredential() {
      this.$emit('update-selected-api', this.localSelectedApi);
    },
    async saveConfig(){
      try {
        await post(this.$clerk, `/sequences`, this.localSelectedApi);
      } catch (error) {
        console.error('Error loading endpoints:', error);
      }
    },
    selectApi(api) {
      const defaultCredential = this.credentials.length > 0 ? this.credentials[0].id : '';
      const newApi = api.method && api.method.length > 0 
        ? { api_request_id: api.api_request_id, path: api.path, method: api.method, credential: defaultCredential, uniqueId: uuidv4() }
        : { path: api.path, method: 'GET', credential: defaultCredential, uniqueId: uuidv4() };
      this.localSelectedApi.push(newApi);
      this.$emit('update-selected-api', this.localSelectedApi);
    },
    removeApi(uniqueId) {
      this.localSelectedApi = this.localSelectedApi.filter(a => a.uniqueId !== uniqueId);
      this.$emit('update-selected-api', this.localSelectedApi);
    },
    onDragStart(index) {
      this.draggingIndex = index;
    },
    onDragOver(index) {
      this.dragOverIndex = index;
    },
    onDragLeave() {
      this.dragOverIndex = null;
    },
    onDrop(index) {
      const draggedItem = this.localSelectedApi[this.draggingIndex];
      this.localSelectedApi.splice(this.draggingIndex, 1);
      this.localSelectedApi.splice(index, 0, draggedItem);
      this.draggingIndex = null;
      this.dragOverIndex = null;
      this.$emit('update-selected-api', this.localSelectedApi);
    }
  },
  async mounted() {
    await this.loadCredentials();
  }
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 0.5rem;
}

.input-group {
  display: flex;
  align-items: center; /* Center items vertically */
}

.icon-container {
  display: flex;
  align-items: center; /* Center the SVG vertically */
  justify-content: center; /* Center the SVG horizontally */
  padding: 0 8px; /* Adjust padding as needed */
  cursor: pointer;
}

.credential-select {
  width: auto;
  min-width: 120px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.icon-container:hover {
  /* background-color: #f0f0f0; */
  transform: scale(1.1);
}

.icon-container:active {
  background-color: #d0d0d0;
  transform: scale(0.9);
}

.icon-container svg {
  transition: fill 0.3s ease;
}

.icon-container:hover svg {
  fill: #007bff;
}

.method-label {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 0.75rem;
}

.method-get {
  background-color: #61affe;
  color: #fff;
}

.method-post {
  background-color: #49cc90;
  color: #fff;
}

.method-delete {
  background-color: #f93e3e;
  color: #fff;
}

.method-patch {
  background-color: #50e3c2;
  color: #fff;
}

.method-put {
  background-color: #fca130;
  color: #fff;
}

.method-default {
  background-color: #fff;
  color: #fff;
}

.draggable {
  cursor: move;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.draggable:hover {
  background-color: #f0f0f0;
}

.draggable:active {
  transform: scale(0.98);
  background-color: #d0d0d0;
}

.drag-over {
  transform: translateY(10px);
  transition: transform 0.2s ease;
}
</style>