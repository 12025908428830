<template>
    <div class="container mt-5 col">
      <h3>Request Details</h3>
      <div v-if="log" class="d-flex justify-content-between">
        <!-- Request Information -->
        <div class="request-info col-6">
          <div class="mb-3">
            <h5>Request Information</h5>
            <p><strong>Method:</strong> {{ log.method }}</p>
            <p><strong>Path:</strong> {{ log.path }}</p>
            <p><strong>Full Request Body:</strong></p>
            <pre>{{ JSON.stringify(log.requestBody, null, 2) }}</pre>
          </div>
          
          <div class="mb-3">
            <h5>Request Headers</h5>
            <pre>{{ JSON.stringify(log.headers, null, 2) }}</pre>
          </div>
          
          <div class="mb-3">
            <h5>Request Variables</h5>
            <p>{{ log.variables }}</p>
          </div>
          
          <div class="mb-3">
            <h5>API Credentials</h5>
            <p>{{ log.credentials }}</p>
          </div>
          
          <div class="mb-3">
            <h5>Status Code</h5>
            <p>
              <strong>Status Code:</strong> 
              <span :class="getBadgeClass(log.statusCode)">{{ log.statusCode }}</span>
            </p>
          </div>
        </div>
  
        <!-- Response Information -->
        <div class="response-info col-6">
          <div class="mb-3">
            <h5>Response</h5>
            <pre>{{ JSON.stringify(log.response, null, 2) }}</pre>
          </div>
        </div>
      </div>
      <router-link to="/">Back to Logs</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "RequestDetails",
    data() {
      return {
        log: null,
      };
    },
    created() {
      const logId = this.$route.params.id;
      // Fetch the log details based on the ID
      const logs = [
        {
          id: 1,
          method: "GET",
          path: "/api/user/123",
          variables: "user-id=123",
          credentials: "API Key 123",
          statusCode: 200,
          response: '{"username":"123123"}',
          requestBody: null, // Assuming GET request has no body
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer token'
          },
        },
        {
          id: 2,
          method: "POST",
          path: "/api/user",
          variables: "name=JohnDoe",
          credentials: "API Key 456",
          statusCode: 201,
          response: "Response details here.sadfasfdasdfsadf..",
          requestBody: {
            name: "JohnDoe"
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer token'
          },
        },
        // Add more logs as needed
      ];
      this.log = logs.find((log) => log.id == logId);
    },
    methods: {
      getBadgeClass(statusCode) {
        if (statusCode >= 200 && statusCode < 300) {
          return 'badge bg-success';
        } else if (statusCode >= 400 && statusCode < 500) {
          return 'badge bg-warning';
        } else if (statusCode >= 500) {
          return 'badge bg-danger';
        } else {
          return 'badge bg-secondary';
        }
      },
    },
  };
  </script>
  
<style>
.request-info, .response-info {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
}
</style>