<template>
  <div class="container col mt-5">
    <h2 class="mb-4">Endpoint Directory</h2>
    <div class="row mb-4">
      <!-- Form to upload API YAML file by URL -->
      <div class="mb-4 col-md-6">
        <h4 class="col">Upload API YAML File by URL</h4>
        <div class="col tooltip-container">
          <span class="question-mark">?</span>
          <div class="tooltip-content">
            <p>
              To update your API endpoint, please follow these steps:
              <ol>
                <li>
                  <strong>Enter the YAML File URL:</strong> Paste the URL of the YAML file in the input field below.
                </li>
                <li>
                  <strong>Upload the File:</strong> Once you've entered the URL, click the "Upload" button.
                </li>
                <li>
                  <strong>Processing:</strong> After clicking "Upload", the system will start processing your file. You will see a loading spinner indicating that the upload is in progress.
                </li>
                <li>
                  <strong>Asynchronous Update:</strong> The system will asynchronously update the endpoint directory with the data from your uploaded YAML file. This ensures that the update process does not block other operations and provides a smoother user experience.
                </li>
                <li>
                  <strong>Completion:</strong> Once the upload and update processes are complete, the changes will be reflected in the API endpoint. You will receive a notification confirming the successful update.
                </li>
              </ol>
              If you encounter any issues during this process, please ensure that the file is in the correct YAML format and try again. For further assistance, contact our support team.
            </p>
          </div>
        </div>
        <div class="container d-flex justify-content-center">
          <form @submit.prevent="uploadFile" class="upload-form">
            <div class="mb-3">
              <input type="url" class="form-control" v-model="fileUrl" placeholder="Enter YAML file URL" required />
            </div>
            <button type="submit" class="btn btn-primary" v-if="!isUploading">
              Upload
            </button>
            <div v-if="isUploading" class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Uploading...</span>
            </div>
          </form>
        </div>
      </div>

      <!-- New endpoint form -->
      <div class="mb-4 col-md-6">
        <h4 class="col">Add Endpoint</h4>
        <div class="container d-flex justify-content-center">
          <form @submit.prevent="addNewEndpoint" class="upload-form">
            <div class="mb-3">
              <select class="form-control" v-model="newEndpoint.hostname" required>
                <!-- <option disabled value="">https://api.github.com</option> -->
                <option v-for="host in hosts" :key="host" :value="host.hostname">{{ host.hostname }}</option>
              </select>
            </div>
            <div class="mb-3">
              <input type="text" class="form-control" v-model="newEndpoint.path" placeholder="Enter Path" required />
            </div>
            <button type="submit" class="btn btn-primary">Add</button>
          </form>
        </div>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Hostname</th>
          <th scope="col">Path</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(endpoint, index) in endpoints" :key="endpoint.id">
          <td>{{ endpoint.hostname || 'Unknown' }}</td>
          
          <td>
            <div v-if="editingIndex === index" class="position-relative">
              <input v-model="editingEndpoint.path" class="form-control position-absolute" style="width: 80%;" />
            </div>
            <div v-else 
                class="clickable-path"
                @click="goToDetails(endpoint.id)"
                v-html="highlightUnknowns(endpoint.path)">
            </div>
          </td>
          
          <td>
            <button v-if="editingIndex === index" class="btn btn-success btn-sm" @click="saveEdit(index)">Save</button>
            <button v-else class="btn btn-primary btn-sm" @click="editEndpoint(index, endpoint)">Edit</button>
            <button class="btn btn-danger btn-sm" @click="confirmDeletion(endpoint.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between">
      <div>
        Showing {{ currentPage * itemsPerPage - itemsPerPage + 1 }} to
        {{ Math.min(currentPage * itemsPerPage, total) }} of {{ total }} entries
      </div>
      <nav>
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { get, del, post, put, uploadFileByUrl } from '@/utils/apiUtils'; // Make sure to implement this function
export default {
  name: 'EndpointDirectory',
  async mounted(){
    await this.loadEndpoints();
    await this.loadHosts();
  },
  data() {
    return {
      endpoints: [],
      currentPage: 1,
      itemsPerPage: 10,
      fileUrl: '',
      isUploading: false,
      total: 0,
      error: null,
      editingIndex: null,
      editingEndpoint: {
        id: null,
        hostname: '',
        path: ''
      },
      newEndpoint: {
        hostname: '',
        path: ''
      },
      hosts: []
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    }
  },
  methods: {
    async addNewEndpoint() {
    try {
        // Find the host ID based on the selected hostname
        const selectedHost = this.hosts.find(host => host.hostname === this.newEndpoint.hostname);
        if (!selectedHost) {
          alert('Invalid host selected');
          return;
        }

        // Create the request body
        const requestBody = {
          host_id: selectedHost.id,
          path: this.newEndpoint.path
        };

        // Make the POST request to add the new endpoint
        const response = await post(this.$clerk, '/endpoints', requestBody);

        // Add the new endpoint to the list
        this.endpoints.unshift(response);

        // Reset the form and hide it
        this.newEndpoint = { hostname: '', path: '' };
      } catch (error) {
        console.error('Error adding new endpoint:', error);
        alert('An error occurred while adding the new endpoint.');
      }
    },
    async loadEndpoints(page = this.currentPage, perPage = this.itemsPerPage){
      try {
        const response = await get(this.$clerk, `/endpoints?per_page=${perPage}&page=${page}`)
        this.endpoints = response.items;
        this.total = response.total;
        this.currentPage = page;
      }catch (error){
        console.error('Error loading endpoints:', error);
        this.error = error.message;
      }
    },
    async uploadFile() {
      if (!this.fileUrl) {
        alert('Please enter a file URL to upload.');
        return;
      }

      this.isUploading = true;

      try {
        await uploadFileByUrl(this.$clerk, '/upload_openapi_yaml', this.fileUrl);
        
        console.log('File uploaded from URL:', this.fileUrl);
        alert('File uploaded successfully');
        // Update the requests array and total here as per your requirement
      } catch (error) {
        console.error('Upload error:', error);
        alert('An error occurred while uploading the file.');
      } finally {
        this.isUploading = false;
        this.fileUrl = '';
      }
    },
    async deleteEndpoint(endpointId){
      try {
        await del(this.$clerk, `/endpoints/${endpointId}`)
        this.endpoints = this.endpoints.filter(endpoint => endpoint.id !== endpointId);
        this.total--;
      }catch (error){
        console.error('Error deleting endpoints:', error);
        this.error = error.message;
      }
    },
    highlightUnknowns(path) {
      if (!path) return '<span class="bg-warning">Unknown</span>';
      return path.replace(/\{([^}]+)\}/g, '<span class="bg-warning">{$1}</span>');
    },
    confirmDeletion(index) {
      if (confirm('Are you sure you want to delete this endpoint?')) {
        this.endpoints.splice(index, 1);
        this.deleteEndpoint(index);
      }
    },
    async prevPage() {
      if (this.currentPage > 1) {
        await this.loadEndpoints(this.currentPage - 1);
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        await this.loadEndpoints(this.currentPage + 1);
      }
    },
    async loadHosts(){
      try {
        const response = await get(this.$clerk, `/hosts`)
        this.hosts = await response.items;
      } catch (error) {
        console.error('Error fetching hosts:', error);
      }
    },
    editEndpoint(index, endpoint) {
      this.editingIndex = index;
      this.editingEndpoint = { ...endpoint };
    },
    goToDetails(id) {
      this.$router.push(`/endpoint-directory/${id}/details`);
    },
    async saveEdit(index) {
      try {
        const requestBody = {
          path: this.editingEndpoint.path
        };
        const response = await put(this.$clerk, `/endpoints/${this.editingEndpoint.id}`, requestBody);
        // Update the local state with the updated endpoint data
        this.endpoints.splice(index, 1, {
          ...this.endpoints[index],
          path: response.path
        });
        this.editingIndex = null;
        this.editingEndpoint = { id: null, hostname: '', path: '' };
      } catch (error) {
        console.error('Error saving endpoint:', error);
        alert('An error occurred while saving the endpoint.');
      }
    }
  },
};
</script>

<style scoped>
.bg-warning {
  background-color: #ffc107 !important;
}
.tooltip-container {
  display: inline-block;
  position: relative;
  margin-left: 10px; /* Adjust the margin as needed */
}

.question-mark {
  color: grey;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px; /* Adjust the size as needed */
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  width: 300px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px; /* Smaller font size for the tooltip */
}

.tooltip-container:hover .tooltip-content {
  display: block;
}

.upload-form {
  max-width: 500px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.clickable-path {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.clickable-path:hover {
  text-decoration: underline;
}
</style>