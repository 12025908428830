// src/clerk.js
import { Clerk } from '@clerk/clerk-js';

const frontendApi = process.env.VUE_APP_CLERK_FRONTEND_API;

if (!frontendApi) {
  throw new Error('Missing Clerk frontend API key');
}

const clerk = new Clerk(frontendApi);

export default clerk;