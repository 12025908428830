<template>
    <div class="container-fluid mt-5 col">
      <div class="row">
        <!-- General Settings -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              General Settings
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label for="apiEndpointUrl" class="form-label">API Endpoint URL</label>
                <input type="text" class="form-control" id="apiEndpointUrl" v-model="generalSettings.apiEndpointUrl">
              </div>
              <div class="mb-3">
                <label for="scanName" class="form-label">Scan Name</label>
                <input type="text" class="form-control" id="scanName" v-model="generalSettings.scanName">
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Description</label>
                <textarea class="form-control" id="description" v-model="generalSettings.description"></textarea>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Authentication Settings -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Authentication Settings
            </div>
            <div class="card-body">
              <div v-for="(credential, index) in authenticationSettings.credentials" :key="index" class="form-check">
                <input class="form-check-input" type="checkbox" :id="'credential-' + index" v-model="credential.selected">
                <label class="form-check-label" :for="'credential-' + index">
                  {{ credential.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Request Configuration -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Request Configuration
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label">Headers</label>
                <div v-for="(header, index) in requestConfiguration.headers" :key="index" class="input-group mb-2">
                  <input type="text" class="form-control" placeholder="Header Name" v-model="header.name">
                  <input type="text" class="form-control" placeholder="Header Value" v-model="header.value">
                  <button class="btn btn-danger" @click="removeHeader(index)">Remove</button>
                </div>
                <button class="btn btn-primary" @click="addHeader">Add Header</button>
              </div>
  
              <div class="mb-3">
                <label class="form-label">HTTP Methods</label>
                <div v-for="(method, index) in requestConfiguration.httpMethods" :key="index" class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" :id="'method-' + method" v-model="requestConfiguration.selectedMethods" :value="method">
                  <label class="form-check-label" :for="'method-' + method">{{ method }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <!-- Scan Scope -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Scan Scope
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label for="rateLimiting" class="form-label">Rate Limiting</label>
                <input type="number" class="form-control" id="rateLimiting" v-model="scanScope.rateLimiting">
              </div>
            </div>
          </div>
        </div>
  
        <!-- Run and Schedule Scan -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Run and Schedule Scan
            </div>
            <div class="card-body">
              <button class="btn btn-success me-2" @click="runScan">Run Now</button>
              <button class="btn btn-secondary" @click="scheduleScan">Schedule Scan</button>
            </div>
          </div>
        </div>
  
        <!-- Scan Results / Scheduled Scans -->
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Scan Results / Scheduled Scans
            </div>
            <div class="card-body">
              <!-- Placeholder content for scan results or scheduled scans -->
              <p>No scans run yet.</p>
              <p>No scans scheduled.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ScanConfiguration",
    data() {
      return {
        generalSettings: {
          apiEndpointUrl: '',
          scanName: '',
          description: ''
        },
        authenticationSettings: {
          credentials: [
            { name: 'Credential 1', selected: false },
            { name: 'Credential 2', selected: false },
            // Add more credentials as needed
          ]
        },
        requestConfiguration: {
          headers: [
            { name: '', value: '' }
          ],
          httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
          selectedMethods: []
        },
        scanScope: {
          rateLimiting: 10
        }
      };
    },
    methods: {
      addHeader() {
        this.requestConfiguration.headers.push({ name: '', value: '' });
      },
      removeHeader(index) {
        this.requestConfiguration.headers.splice(index, 1);
      },
      runScan() {
        // Logic to run the scan immediately
        console.log('Running scan with settings:', this.$data);
      },
      scheduleScan() {
        // Logic to schedule the scan
        console.log('Scheduling scan with settings:', this.$data);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Ensure the page height does not exceed 100% of the viewport */
  html, body, #app {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .container-fluid {
    height: 100%;
  }
  
  .row {
    height: 50%;
  }
  
  .card {
    height: 100%;
    overflow: auto;
  }
  </style>