const API_HOST = process.env.VUE_APP_API_HOST;

// src/utils/apiUtils.js
export async function getToken(clerk) {
    try {
      const session = clerk.session;
      if (!session) {
        throw new Error('No active session found');
      }
  
      const token = await session.getToken({ template: "oneweek" });
      return token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error; // Re-throw the error so it can be handled by the caller
    }
  }
  
  async function makeRequest(clerk, method, path, body = null) {
    const token = await getToken(clerk);
    const url = `${API_HOST}${path}`;
  
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };
  
    if (body) {
      options.body = JSON.stringify(body);
    }
  
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Network response was not ok');
    }
  
    return response.json();
  }
  
  export function get(clerk, path) {
    return makeRequest(clerk, 'GET', path);
  }
  
  export function post(clerk, path, body) {
    return makeRequest(clerk, 'POST', path, body);
  }

  export function put(clerk, path, body) {
    return makeRequest(clerk, 'PUT', path, body);
  }
  
  export function del(clerk, path) {
    return makeRequest(clerk, 'DELETE', path);
  }
  
  export async function uploadFileByUrl(clerk, path, file_url) {
    const token = await getToken(clerk);
    const url = `${API_HOST}${path}`;
  
    const payload = {
      file_url: file_url
    };
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Network response was not ok');
    }
  
    return response.json();
  }