<template>
  <div class="container-fluid max-width">
    <SideBar @menu-click="changeComponent"/>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue';

export default {
  name: 'App',
  components: {
    SideBar,
  },
  methods: {
    changeComponent(componentName) {
      if (componentName === 'RequestLogs') {
        this.$router.push('/request-logs');
      } else if (componentName === 'CredentialsVault') {
        this.$router.push('/');
      } else if (componentName === 'EndpointDirectory') {
        this.$router.push('/endpoint-directory');
      } else if (componentName === 'ScanConfiguration') {
        this.$router.push('/scan-configuration');
      } else if (componentName === 'VariableAssignment') {
        this.$router.push('/variable-assignment');
      } else if (componentName === 'SequenceBuilder') {
        this.$router.push('/sequence-builder');
      }

    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container-fluid {
  display: flex;
  flex-direction: row;
}
.content {
  margin-left: 280px; /* Same width as the sidebar to avoid overlapping */
  padding: 20px; /* Optional: Adds some padding to the content area */
  flex: 1; /* Allows the content area to take up the remaining space */
  overflow-y: auto; /* Makes the content area scrollable */
}
</style>