// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import clerk from './clerk';

clerk.load().then(() => {
  const app = createApp(App);

  // Add Clerk to global properties so it can be accessed in any component
  app.config.globalProperties.$clerk = clerk;

  app.use(router).mount('#app');
}).catch(error => {
  console.error('Failed to load Clerk:', error);
});