<template>
  <div class="container col mt-5">
    <h2 class="mb-4">Credentials Vault</h2>
    <div class="row mb-4">
      <!-- Form to add new credential -->
      <div class="mb-4 col-md-6">
        <h4 class="col">Add Credential</h4>
        <div class="container d-flex justify-content-center">
          <form @submit.prevent="addCredential" class="upload-form">
            <div class="mb-3">
              <input type="text" class="form-control" v-model="newCredential.name" placeholder="Credential Name" required />
            </div>
            <div class="mb-3">
              <input type="text" class="form-control" v-model="newCredential.value" placeholder="Credential Key" required />
            </div>
            <div class="mb-3">
              <select class="form-control" v-model="newCredential.permission" required>
                <option value="Full">Full</option>
                <option value="Partial">Partial</option>
                <option value="None">None</option>
              </select>
            </div>
            <div class="mb-3">
              <select class="form-control" v-model="newCredential.role" required>
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="guest">Guest</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary">Add Credential</button>
          </form>
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <h5 class="card-title mt-3">Stored Credentials</h5>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="thead-dark">
            <tr>
              <th class="text-center">Name</th>
              <th class="text-center">Value</th>
              <th class="text-center">Permission</th>
              <th class="text-center">Role</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="credential in credentials" :key="credential.id">
              <td class="text-center">{{ credential.name }}</td>
              <td class="text-center">{{ credential.value }}</td>
              <td class="text-center">
                <span :class="getPermissionLabelClass(credential.permission)">{{ credential.permission }}</span>
              </td>
              <td class="text-center">
                <span :class="getRoleLabelClass(credential.role)">{{ credential.role }}</span>
              </td>
              <td class="text-center">
                <button class="btn btn-danger btn-sm" @click="removeCredential(credential.id)">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div>
        Showing {{ currentPage * itemsPerPage - itemsPerPage + 1 }} to
        {{ Math.min(currentPage * itemsPerPage, total) }} of {{ total }} entries
      </div>
      <nav>
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { get, post, del } from '@/utils/apiUtils'; // Ensure you have these utility functions implemented

export default {
  name: 'CredentialsVault',
  async mounted() {
    await this.loadCredentials();
  },
  data() {
    return {
      credentials: [],
      newCredential: {
        name: '',
        value: '',
        permission: 'Full',
        role: 'user'
      },
      currentPage: 1,
      itemsPerPage: 10,
      total: 0,
      error: null
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    }
  },
  methods: {
    async addCredential() {
      try {
        const response = await post(this.$clerk, '/credentials', this.newCredential);
        this.credentials.unshift(response);
        this.newCredential = { name: '', value: '', permission: 'Full', role: 'user' };
        this.total++;
      } catch (error) {
        console.error('Error adding credential:', error);
        alert('An error occurred while adding the credential.');
      }
    },
    async loadCredentials(page = this.currentPage, perPage = this.itemsPerPage) {
      try {
        const response = await get(this.$clerk, `/credentials?per_page=${perPage}&page=${page}`);
        this.credentials = response.items;
        this.total = response.total;
        this.currentPage = page;
      } catch (error) {
        console.error('Error loading credentials:', error);
        this.error = error.message;
      }
    },
    async removeCredential(credentialId) {
      try {
        await del(this.$clerk, `/credentials/${credentialId}`);
        this.credentials = this.credentials.filter(credential => credential.id !== credentialId);
        this.total--;
      } catch (error) {
        console.error('Error removing credential:', error);
        this.error = error.message;
      }
    },
    getPermissionLabelClass(permission) {
      switch (permission) {
        case 'Full':
          return 'badge bg-success';
        case 'Partial':
          return 'badge bg-warning';
        case 'None':
          return 'badge bg-danger';
        default:
          return '';
      }
    },
    getRoleLabelClass(role) {
      switch (role) {
        case 'user':
          return 'badge bg-primary';
        case 'admin':
          return 'badge bg-secondary';
        case 'guest':
          return 'badge bg-info';
        default:
          return '';
      }
    },
    async prevPage() {
      if (this.currentPage > 1) {
        await this.loadCredentials(this.currentPage - 1);
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        await this.loadCredentials(this.currentPage + 1);
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>