<template>
  <div id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 280px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
      <img src="@/assets/logo.png" width="36" height="36">
      <span class="fs-4 mx-4">IDORable</span>
    </a>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li v-for="item in menuItems" :key="item.name" class="nav-item">
        <a @click="menuClick(item.component)" href="#" class="nav-link text-white d-flex align-self-center">
          <component :is="item.icon" class="mx-2" />
          <p class="mx-2">{{ item.label }}</p>
        </a>
      </li>
    </ul>
    <hr>
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img :src="userImageUrl" alt="User profile image" width="32" height="32" class="rounded-circle me-2">
        <strong>{{ userName }}</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#" @click="testClerk">Settings</a></li>
        <li><a class="dropdown-item" href="#" @click="openUserProfile">Profile</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="#" @click="signOut">Sign out</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/apiUtils';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SideBar',
  data() {
    return {
      userImageUrl: '',
      userName: '',
      menuItems: [
        {
          name: 'CredentialsVault',
          component: 'CredentialsVault',
          label: 'Credential Vault',
          icon: 'KeyIcon'
        },
        {
          name: 'EndpointDirectory',
          component: 'EndpointDirectory',
          label: 'Endpoint Directory',
          icon: 'FolderIcon'
        },
        // {
        //   name: 'RequestLogs',
        //   component: 'RequestLogs',
        //   label: 'Request Logs',
        //   icon: 'ClipboardIcon'
        // },
        // {
        //   name: 'ScanConfiguration',
        //   component: 'ScanConfiguration',
        //   label: 'Scan Configuration',
        //   icon: 'SettingsIcon'
        // },
        // {
        //   name: 'VariableAssignment',
        //   component: 'VariableAssignment',
        //   label: 'Variable Assignment',
        //   icon: 'TagIcon'
        // },
        {
          name: 'SequenceBuilder',
          component: 'SequenceBuilder',
          label: 'Sequence Builder',
          icon: 'LayersIcon'
        }
      ]
    };
  },
  created() {
    const user = this.$clerk.user;
    if (user) {
      this.userImageUrl = user.imageUrl || 'default-profile.png';
      this.userName = user.fullName || user.primaryEmailAddress || 'User';
    }
  },
  methods: {
    async testClerk() {
      try {
        const data = await get(this.$clerk, '/users');
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    menuClick(componentName) {
      this.$emit('menu-click', componentName)
    },
    openUserProfile() {
      this.$clerk.openUserProfile();
    },
    signOut() {
      this.$clerk.signOut().then(() => {
        window.location.href = '/';
      });
    }
  }
});
</script>

<style scoped>
#sidebar {
  height: 100vh;   /* Sets the height to 100% of the viewport height */
  width: 280px;
  position: fixed; /* Fixes the sidebar to the left side of the viewport */
  top: 0;
  left: 0;
  z-index: 1000;   /* Ensures the sidebar is on top of other content */
  overflow-y: auto; /* Allows the sidebar to be scrollable if content overflows */
}
.nav-link {
  text-align: left;
}
.icon {
  color: white;
}
</style>